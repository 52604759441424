import {
  makeResponsive,
  placeTitle,
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  placeGravity,
  placeText,
  placeMiddleText,
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeCircle,
  placeAngle,
  placeDash,
  placeLabel,
  placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
  placeArc,
  placeLogo,
  placeErase,
  placeShuffle,
  placeTest,
  placeSeg,
  setConfig,
  placeStat,
  drawTriangle,
  shuffle,
  placeEraseLeft,
  placeTapeHori,
  placeTapeVert,
  placeSq,
  placeBoldText,
  placeRedo,
  placeUndo,
  drawPoint,
  drawTri,
  setSize,
  labelIt,
  drawDash,
  placeEggs,
  drawLine,
  setWidth,
  drawIntersection,
  drawPerpP,
  drawAngle,
  createSpace,
  createAxes,
  writeHTMLText
} from '../Utils';
const Boxes = {
    //JXG.Options.line.highlight=false;
    box1: function () {
    //Create the Board
    var graph =createSpace(-6,6,-6,6);
    //graph.suspendUpdate();
    //createAxes(graph);
    // Layering
    graph.options.axis.strokeWidth=0;
    graph.options.layer['image']=17;
    graph.options.layer['text']=19;
    graph.options.layer['line']=16;
    graph.options.layer['point']=17;
    graph.options.layer['glider']=17;
    graph.options.layer['angle']=18;
    graph.options.board.minimizeReflow = 'none';
    graph.options.point.showInfobox =false;
    graph.options.line.highlight=false;
    graph.options.image.highlight=false;
    graph.options.text.highlight=false;
    graph.options.polygon.highlight=false;
//    graph.options.text.display='internal';
    //Make the board responsive
    makeResponsive(graph);
    // Creat titleStyle
    placeLogo(graph);
    placeTitle(graph, 'Laws of Exponents', '(Multiplication and Division of Exponents)');
////////////////////////////////////////////////////////////////////////////////
    var analytics = placeUndo(graph, 'left');
    hoverMe(graph, analytics, 'Previous Step', 0, -5);
    ////////////////////////////////////////////////////
    var erase = placeRedo(graph);
    hoverMe(graph, erase, 'Next Step', 0, -5);
/////////////////////////////////////////////////////////////////////////////
    var i = 0;
    var eggs = placeEggs(graph);
    eggs[0].setAttribute({visible:()=>i>=0});
    ////////////////
    eggs[1].setAttribute({visible:()=>i>=1});
    ////////////////
    eggs[2].setAttribute({visible:()=>i>=2});
    ///////////////////////////All actions go here////////////////////////////////
    var txt1 = writeHTMLText(graph, 0, -0.5, '(a^m) x (a^n) = a^m^+^n');
    txt1.setAttribute({color:'red', fontSize: () => Math.round(42*graph.canvasWidth /800.), visible:()=>i==0});
    //////////////////////////
    var txt2 = writeHTMLText(graph, 0, -0.5, '(a^m) / (a^n) = a^m^-^n');
    txt2.setAttribute({fixed:true, color:'red', fontSize: () => Math.round(42*graph.canvasWidth /800.), visible:()=>i==1});
    ////////////////////////
    var txt3 = writeHTMLText(graph, 0, -0.5, '(a^m)^n = a^m^n');
    txt3.setAttribute({color:'red', fontSize: () => Math.round(42*graph.canvasWidth /800.), visible:()=>i==2});
    //////////////////
    var txt4 = writeHTMLText(graph, 0, -0.5, '(a^m) x (b^m) = (ab)^m');
    txt4.setAttribute({color:'red', fontSize: () => Math.round(42*graph.canvasWidth /800.), visible:()=>i==3});
  //////////////////////////////Button Actions////////////////////////////////////
    analytics.on('down', function(){
      if(i>0)
      {
      i-=1;
      }
      else
      {
        i=0;
      }
    });
    erase.on('down', function(){
      if(i<3)
      {
      i+=1;
      }
      else
      {
        i=0;
      }
    })
    var line1 = placeMiddleText(graph, 0, 2.5, 'Under multiplication, the exponents on the same base add.');
    line1.setAttribute({color:'blue', visible:()=>i==0});
    var line2 = placeMiddleText(graph, 0, 2.5, 'Under division, the exponents on the same base substract.');
    line2.setAttribute({color:'blue', visible:()=>i==1});
    var line3 = placeMiddleText(graph, 0, 2.5, 'Two exponents on the same base multiply.');
    line3.setAttribute({color:'blue', visible:()=>i==2});
    var line4 = placeMiddleText(graph, 0, 2.5, 'Two bases with the same exponent multiply.');
    line4.setAttribute({color:'blue', visible:()=>i==3});
///////////////////////////////////////////////////////////////////////
    //graph.unsuspendUpdate();
                      },
              }
export default Boxes;
